<template>
  <v-row class="d-flex flex-column" :loading="ui.loading">
    <v-col>
      <v-row>
        <v-breadcrumbs :items="breadcrumbs" class="px-4">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
            >
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>
      <v-row class="pt-1">
        <v-col cols="12">
        </v-col>
        <v-col cols="12" sm="4" class="pt-0 d-xs-none">
          <v-card
            class="mx-auto"
          >
            <v-img v-if="product.image"
                   aspect-ratio="2"
                   contain
                   :src=product.image
            ></v-img>
            <v-card-title v-if="product.label">
              {{ product.label }}
            </v-card-title>
            <v-card-subtitle v-if="product.description_short">
              {{ product.description_short }}
            </v-card-subtitle>
            <v-card-text class="clear-both">
              <div>
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.reference')
                                 }}</span>
                <span class="float-right">{{ product.reference }}</span>
              </div>
              <div v-if="product.manufacturer">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.manufacturer')
                                 }}</span>
                <span class="float-right">{{ product.manufacturer }}</span>
              </div>
              <div class="d-inline">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.price')
                                 }}</span>
                <span class="float-right">{{ helpers.toEuro(product.price_taxless) }}</span>
              </div>
            </v-card-text>
            <v-card-actions class="clear-both">
              <v-btn
                color="orange lighten-2"
                text
              >
                {{
                  $vuetify.lang.t('$vuetify.product.products.see.ui.intro.more')
                }}
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-text>
                  <!-- Main informations component  -->
                  <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                    <v-icon class="pr-3" color="purple">mdi-tag-text-outline</v-icon>
                    <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.title') }}
                      </span>
                  </div>
                  <div>
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.reference')
                                 }}</span>
                    <span class="float-right">{{ product.reference }}</span>
                  </div>
                  <div>
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.reference_constructor')
                                 }}</span>
                    <span class="float-right">{{ product.reference_constructor }}</span>
                  </div>
                  <div v-if="product.reference_provider">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.reference_provider')
                                 }}</span>
                    <span class="float-right">{{ product.reference_provider }}</span>
                  </div>
                  <div v-if="product.ean">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.ean')
                                 }}</span>
                    <span class="float-right">{{ product.ean }}</span>
                  </div>
                  <div v-if="product.manufacturer">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.manufacturer')
                                 }}</span>
                    <span class="float-right">{{ product.manufacturer }}</span>
                  </div>
                  <div>
                    <span class="">{{
                        $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.specification.title')
                      }}</span>
                    <span v-if="product.is_service" class="float-right">{{
                        $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.specification.is_service')
                      }}</span>
                    <span v-else-if="product.is_solution" class="float-right">{{
                        $vuetify.lang.t('$vuetify.product.products.see.ui.intro.main.specification.is_solution')
                      }}</span>
                  </div>
                  <!-- Price and taxes component  -->
                  <div class="overline text-uppercase font-weight-bold pb-2 pt-2 mt-4">
                    <v-icon class=" pr-3" color="purple">mdi-piggy-bank-outline</v-icon>
                    <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.title') }}
                      </span>
                  </div>
                  <div>
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.financial.price')
                                 }}</span>
                    <span class="float-right">{{ helpers.toEuro(product.price_taxless) }}</span>
                  </div>
                  <div>
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.financial.price_starting')
                                 }}</span>
                    <span class="float-right">{{ helpers.toEuro(product.price_starting) }}</span>
                  </div>
                  <div>
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.financial.rent')
                                 }}</span>
                    <span class="float-right">{{ helpers.toEuro(product.rent_taxless) }}</span>
                  </div>
                  <div class="d-inline">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.financial.nb_months')
                                 }}</span>
                    <span class="float-right">{{ product.rent_months }}</span>
                  </div>
                  <div v-if="!_.isEmpty(product.vat)">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.financial.vat')
                                 }}</span>
                    <span class="float-right">{{
                        product.vat.code + ' : ' + product.vat.value + '%' }}</span>
                  </div>
                  <div v-if="!_.isEmpty(product.taxes)">
                  <span class="">{{
                      $vuetify.lang.t('$vuetify.product.products.see.ui.intro.financial.taxes')
                                 }}</span>
                    <span class="d-flex flex-column">
                    <span class="text-right mb-0" v-for="tax in product.taxes" :key="tax.id">
                      {{
                        tax.name + ' : ' +  (tax.tax_type === "%" ? tax.value + '%' : helpers.toEuro(tax.value)) }}
                    </span></span><br>
                  </div>
                  <!-- End price and taxes component  -->
                  <!-- Product families component  -->
                  <div class="overline text-uppercase font-weight-bold pb-2 pt-2 mt-4">
                    <v-icon class=" pr-3" color="purple">mdi-shape-outline</v-icon>
                    <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.intro.category.title') }}
                      </span>
                  </div>
                  <div v-if="_.isEmpty(product.families)">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.intro.category.none') }}
                  </div>
                  <div v-else>
                    <ul>
                      <li v-for="family in product.families" :key="family.id">{{ family.parent }}</li>
                    </ul>
                  </div>
                  <!-- End product families component  -->
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
          <!-- Images component  -->
          <v-card v-if="!_.isEmpty(product.images)" outlined
                  class="rounded-lg overflow-hidden mt-6">
            <v-carousel
              cycle
              height="400"
              width="400"
              contain
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(image, i) in product.images"
                :key="i"
                :src="image.path"
                contain
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <!-- End images component  -->
          <!-- Files component  -->
          <v-card v-if="!_.isEmpty(product.files)" outlined
                  class="rounded-lg overflow-hidden mt-4">
            <v-card-text class="pb-0">
              <div class="">
                <v-row>
                  <v-col>
                    <div class="overline text-uppercase font-weight-bold pb-2">
                      <v-icon class="pr-3" color="purple">mdi-paperclip</v-icon>
                      <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.files.name') }}
                      </span>
                    </div>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </div>
              <v-card-text>
                <v-row v-for="file in product.files" :key="file.id">
                  <v-col cols="12" sm="11">
                    {{ file.name }}
                  </v-col>
                  <v-col cols="12" sm="1" class="text-center ">
                    <v-btn
                      color="success"
                      fab
                      x-small
                      dark
                      target="_blank"
                      :href="file.dwl_path"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card-text>
          </v-card>
          <!-- End files component  -->
        </v-col>
        <v-col cols="12" sm="8" class="pt-0">
          <v-card outlined
                  :loading="ui.loading"
                  :disabled="ui.disabled"
                  class="rounded-lg overflow-hidden">
            <template slot="progress">
              <v-progress-linear
                color="blue-grey darken-3"
                height="6"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text class="px-0 pb-0">
              <div class="px-5 px-sm-9 pt-6 mb-12">
                <v-row>
                  <!-- Product description component  -->
                  <v-col>
                    <div class="overline text-uppercase font-weight-bold pb-2">
                      <v-icon class="pl-3 pr-3" color="purple">mdi-note-text-outline</v-icon>
                      <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.intro.description.title') }}
                      </span>
                    </div>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="12" class="mb-2">
                    <quillEditor ref="quillEditor" v-if="product.description" v-model="product.description"
                                 :options="editorOptions" disabled></quillEditor>
                    <span v-if="_.isEmpty(product.description)">
                      {{ $vuetify.lang.t('$vuetify.product.products.see.ui.intro.description.none') }}
                    </span>
                  </v-col>
                  <!-- Product characteristics component  -->
                  <v-col>
                    <div class="overline text-uppercase font-weight-bold pb-2">
                      <v-icon class="pl-3 pr-3" color="purple">mdi-wrench-outline</v-icon>
                      <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.intro.characteristic.title') }}
                      </span>
                    </div>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="12" class="mb-2">
                    <wysiwyg v-if="product.characteristics"  :disabled="true" v-model="product.characteristics" :options="charOptions"></wysiwyg>
                    <span v-if="_.isEmpty(product.characteristics)">
                                         {{
                        $vuetify.lang.t('$vuetify.product.products.see.ui.intro.characteristic.none')
                                         }}
                    </span>
                  </v-col>
                  <!-- Product bundle component  -->
                  <v-col v-if="!_.isEmpty(product.bundle) ">
                    <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                      <v-icon class="pl-3 pr-3" color="purple">mdi-video-input-component</v-icon>
                      <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.bundle.title') }}
                      </span>
                    </div>
                    <v-divider></v-divider>
                    <v-col cols="12">
                      <v-data-table :headers="bundleDatable.headers" :items="product.bundle"
                                    :hide-default-footer="true"
                                    dense>
                        <template v-slot:item.reference="{ item }">
                            <span class="font-weight-bold" style="cursor: pointer" v-on:click="$router.push({name:'GeneralProductsOne', params:{
                      id : item.id
                    }})">
                              {{ item.reference }}
                            </span>
                        </template>
                        <template v-slot:item.quantity="{ item }">
                        <span class="font-weight-bold">
                              {{ item.pivot.qt }}
                            </span>
                        </template>
                        <template v-slot:item.price_taxless="{ item }">
                            <span class="font-weight-bold">
                              {{ helpers.toEuro(item.price_taxless) }}
                            </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-col>
                  <!-- Product similar component  -->
                  <v-col cols="12" class="mt-2">
                    <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                      <v-icon class="pl-3 pr-3" color="purple">mdi-google-circles-extended</v-icon>
                      <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.similar.title') }}
                      </span>
                    </div>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="12" class="mb-2" v-if="_.isEmpty(product.similar)">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.similar.none') }}
                  </v-col>
                  <v-row dense class="mt-4" v-else>
                    <v-col
                      v-for="similar in product.similar"
                      :key="similar.id"
                      cols="4"
                    >
                      <v-card v-on:click="$router.push({name:'GeneralProductsOne', params:{
                      id : similar.id
                    }})">
                        <v-img
                          class="white--text align-end"
                          :src="similar.image_path"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="200px"
                        >
                          <v-card-title v-text="similar.label"></v-card-title>
                        </v-img>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn icon>
                            <v-icon color="orange lighten-2">mdi-eye</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Product extra component  -->
                  <v-col cols="12" class="mt-2">
                    <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                      <v-icon class="pl-3 pr-3" color="purple">mdi-google-circles-communities</v-icon>
                      <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.extra.title') }}
                      </span>
                    </div>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="12" class="mb-2" v-if="_.isEmpty(product.extra)">
                    {{ $vuetify.lang.t('$vuetify.product.products.see.ui.extra.none') }}
                  </v-col>
                  <v-row v-else dense class="mt-4">
                    <v-col
                      v-for="extra in product.extra"
                      :key="extra.id"
                      cols="4"
                    >
                      <v-card v-on:click="$router.push({name:'GeneralProductsOne', params:{
                      id : extra.id
                    }})">
                        <v-img
                          :src="extra.image_path"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="200px"
                        >
                          <v-card-title v-text="extra.label"></v-card-title>
                        </v-img>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn icon>
                            <v-icon color="orange lighten-2">mdi-eye</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey lighten-1"
              fab
              dark
              small
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'GeneralProductsList'}">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.back') }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              fab
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'GeneralProductsEdit'}">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.edit') }}</span>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </v-row>
</template>
<script>

import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor
  },
  data: () => ({
    product: {},
    // used to show more information about the product
    show: false,
    // content of description
    editorOptions: {
      readOnly: true,
      disabled: true,
      modules: {
        toolbar: false
      }
    },
    charOptions: {
      // limit content height if you wish. If not set, editor size will grow with content.
      maxHeight: '300px',
      // specify editor locale, if you don't specify this, the editor will default to english.
      locale: 'fr'
    },
    ui: {
      loading: true,
      disabled: false
    },
    bundleDatable: {
      headers: [],
      items: []
    },
    breadcrumbs: []
  }),
  methods: {
    async fetchProduct () {
      try {
        this.ui.loading = true
        this.product = (await this.axios.get('/general/products/one/' + this.$router.currentRoute.params.id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 404:
              await this.$router.push({
                name: 'ProductsList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.product.products.see.notification.not_found')
                  }
                }
              })
              break

            default:
              await this.$router.push({
                name: 'ProductsList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert-octagon',
                    type: 'error',
                    message: this.$vuetify.lang.t('$vuetify.product.products.see.notification.unknown_error')
                  }
                }
              })
          }
        }
      } finally {
        this.ui.loading = false
      }
    }
  },
  computed: {},
  watch: {
    // call again the method if the route changes
    $route: 'fetchProduct'
  },
  created () {
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.name'),
        disabled: true,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.product.name'),
        disabled: false,
        to: { name: 'GeneralProductsList' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.product.products.see.name'),
        disabled: true
      }
    ]
    this.bundleDatable.headers = [
      {
        value: 'reference',
        text: this.$vuetify.lang.t('$vuetify.product.products.see.ui.bundle.datatable.reference')
      },
      {
        value: 'label',
        text: this.$vuetify.lang.t('$vuetify.product.products.see.ui.bundle.datatable.label')
      },
      {
        value: 'quantity',
        text: this.$vuetify.lang.t('$vuetify.product.products.see.ui.bundle.datatable.quantity')
      },
      {
        value: 'price_taxless',
        align: 'right',
        text: this.$vuetify.lang.t('$vuetify.product.products.see.ui.bundle.datatable.price_taxless')
      }
    ]
  },

  mounted () {
    this.fetchProduct()
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .ql-container {
    height: 400px;
    max-height: 400px;
    min-height: 100%;
    overflow-y: auto;
  }

  .ql-editor {
    height: auto;
  }

}
</style>
